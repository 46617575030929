import Title from "./components/Title/Title";
import Paragraph from "./components/Paragraph/Paragraph";
import Button from "./components/Button/Button";
import OptionsItem from "./components/OptionsItem/OptionsItem";
import React, { useEffect, useState } from "react";
import imageStartScreen from "./assets/images/00.webp";
import imageResult from "./assets/images/9.webp";
import styles from "./App.module.scss";
import questions from "./state/questions";
import classNames from "classnames";
import Result from "./components/Result/Result";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0); // считаем номер вопроса
  const [stateFlow, setStateFlow] = useState("Start"); // состояние флоу: стартовый экран / вопросы / результат
  const [option, setOption] = useState(false); // выбран ли ответ
  const [resultState, setResultState] = useState(""); // выводить ли результат
  const [resultCount, setResultCount] = useState(0); // счетчик правильных вопросов
  const phone = document.getElementById("phone"); // находим область телефона на десктопе

  // console.log(resultCount); // тестирование логики

  // прокрутка вниз при нажатии на ответ
  const optionActiveHandler = (option) => {
    setOption(option);

    setTimeout(() => {
      window.scrollTo({ top: 1000, behavior: "smooth" });
      phone.scrollTo({ top: 1000, behavior: "smooth" });
    }, "50");
  };

  const resultCountLogic = (result) => {
    setResultCount(resultCount + result);
  };

  // поднимаемся наверх после перехода на страницу
  const scrollReset = (event) => {
    window.scrollTo(0, 0);
    phone.scrollTo(0, 0);
  };

  // Скролл наверх при нажатии старт квиз
  const startFlow = () => {
    setStateFlow("Flow");
    window.scrollTo(0, 0);
  };

  // логика флоу
  const handlerNext = () => {
    if (currentQuestion === 5) {
      setStateFlow("End");

      setResultState(() => {
        if (resultCount <= 5) {
          return (
            <Result
              score={resultCount + "/6"}
              title="Thank you for your help!"
              text={
                "It would’ve been even harder without you. Maximka moved all his stuff in one go and got to spend the evening tidying up his new apartment, and that’s what counts. See for yourself how handy it is to move things with Maxim. Use the Maxim app when you need to transport furniture, building materials or household appliances. Choose the rate you need in the Trucks tab."
              }
              imageSrc={imageResult}
              posterContainer={styles.posterContainer}
              optionsContainer={styles.optionsContainer}
              handlerBack={handlerBack}
            />
          );
        }

        if (resultCount >= 6 && resultCount <= 6) {
          return (
            <Result
              score={resultCount + "/6"}
              title="Everything went great."
              text={
                "Maximka moved all his stuff in one go and got to spend the evening tidying up his new apartment. See for yourself how handy it is to move things with Maxim. Use the Maxim app when you need to transport furniture, building materials or household appliances. Choose the rate you need in the Trucks tab."
              }
              imageSrc={imageResult}
              posterContainer={styles.posterContainer}
              optionsContainer={styles.optionsContainer}
              handlerBack={handlerBack}
            />
          );
        }
      });

      // сбрасываем  скролл для результата
      scrollReset();
    } else {
      setOption(false);
      setCurrentQuestion(currentQuestion + 1);

      // прокрутка вверх после нажатия на ответ
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        phone.scrollTo({ top: 0, behavior: "smooth" });
      }, "50");
    }
  };

  // возвращение в начало
  const handlerBack = () => {
    setOption(false);
    setStateFlow("Start");
    setCurrentQuestion(0);
    setResultCount(0);
  };

  // считаем цифры прокрутки и верешаем options::after
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.wrapper}>
        <div className={styles.phone} id="phone">
          {stateFlow === "Start" ? (
            <>
              <div className={styles.posterContainer}>
                <img src={imageStartScreen} className={styles.image} alt="" />
              </div>
              <div
                className={classNames({
                  [styles.optionsContainer]: true,
                  [styles.onScrollOption]: offset,
                })}
              >
                <div className={styles.bgWhite}>
                  <div className={styles.contentWrapper}>
                    <Title value="Moving is as easy as ABC" />
                    <Paragraph value="Maximka is moving apartments. Packing, transporting all the boxes … There’s a lot to be done. He needs your help!" />
                  </div>
                  <Button value="Go!" onClick={() => startFlow()} id="start" />
                </div>
              </div>
            </>
          ) : stateFlow === "Flow" ? (
            <>
              <div className={styles.posterContainer}>
                <img
                  src={questions[currentQuestion].image}
                  className={styles.image}
                  alt={questions[currentQuestion].image}
                />
              </div>
              <div
                className={classNames({
                  [styles.optionsContainer]: true,
                  [styles.onScrollOption]: offset,
                })}
              >
                <div className={styles.bgWhite}>
                  <div className={styles.header}>
                    <div className={styles.topBarContainer}>
                      <div>
                        Question {currentQuestion + 1} of {questions.length}
                      </div>
                    </div>
                    <Title value={questions[currentQuestion].questionText} />
                  </div>

                  <div className={styles.optionsWrapper}>
                    {questions[currentQuestion].answerOptions.map((item) => (
                      <OptionsItem
                        key={item.answerText}
                        title={item.answerText}
                        resultText={item.answerResult}
                        onClick={optionActiveHandler}
                        disabled={option}
                        result={item.result}
                        resultCountLogic={resultCountLogic}
                      />
                    ))}
                  </div>

                  <Button
                    value="Next"
                    onClick={() => handlerNext()}
                    disabled={!option}
                    id={"btn" + (currentQuestion + 1)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>{resultState}</>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
